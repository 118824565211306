// This indicates that we haven't yet been initialized
let disable = null;

// The code is set up this way so that this initialization code doesn't
// need to run more than once, and must only be run via a frontend React
// method (useEffect / componentDidMount / etc.) due to its use of the
// query string
const initialize = () => {
    if (disable !== null)
        return;

    // Set default values
    disable = {
        animation: false,
        entryModal: false,
        isiTray: false,
        cookieBanner: false
    };

    // Check if the query string contains an expected "screenshot-mode" specifications
    const match = /[?&]screenshot-mode=([a-z]+(,[a-z]+)*)/.exec(window.location.search);
    if (match) {
        // Parse the specified screenshot mode options to determine
        // what features to disable on the site
        const options = match[1].split(",");
        for (let i = 0; i < options.length; i++) {
            switch (options[i]) {
                case "all":
                    // Disable all options
                    for (const key in disable)
                        disable[key] = true;
                    break;

                case "animation":
                    disable.animation = true;
                    break;

                case "modal":
                    disable.entryModal = true;
                    break;

                case "isi":
                    disable.isiTray = true;
                    break;

                case "cookie":
                    disable.cookieBanner = true;
                    break;

                default:
                    break;
            }
        }
    }
};

export default class ScreenshotMode {
    static get disableAnimation() {
        initialize();
        return disable.animation;
    }

    static get disableEntryModal() {
        initialize();
        return disable.entryModal;
    }

    static get disableIsiTray() {
        initialize();
        return disable.isiTray;
    }

    static get disableCookieBanner() {
        initialize();
        return disable.cookieBanner;
    }
}
