import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import logo from "../../images/Auryxia-logo.svg"

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		flexFlow: 'row wrap',
		outline: '1px dotted #eee',
	},
	modalCloseBttn: {
		position: 'absolute',
		top: '18px',
		right: '18px',
		width: '20px',
		height: '20px',
	},
	modalCloseSvg: {
		width: '20px',
		height: '20px',
	},
	modalLogo: {
		maxWidth: '180px',
		height: 'auto',
	},
	modalText: {
		fontSize: '3rem',
		lineHeight: '25px',
		fontFamily: "'Avenir LT W01_65 Medium1475532', sans-serif",
		margin: '29px auto',
		[theme.breakpoints.up('sm')]: {
			fontSize: '30px',
			lineHeight: '5rem',
			margin: '6rem auto 6.75rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
			lineHeight: '3rem',
		},
	},
	bttnWrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		flexFlow: 'row wrap',
		width: '100%',
	},
	cta: {
		width: 'auto',
		minHeight: '45px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'row wrap',
		fontSize: '2rem',
		lineHeight: '3rem',
		padding: '2rem',
		margin: '0.5rem 1rem',
		borderWidth: '2px',
		borderStyle: 'solid',
		textTransform: 'uppercase',
		backgroundColor: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		fontFamily: "'Avenir LT W01_95 Black1475556', sans-serif",
		letterSpacing: '0.5px',
		transition: 'all 200ms cubic-bezier(0.215, 0.61, 0.355, 1)',
		'&:hover': {
			color: 'white',
		},
		'&:nth-child(1)': {
			marginBottom: '2rem',
		},
		[theme.breakpoints.up('475px')]: {
			whiteSpace: 'nowrap',
		},
		[theme.breakpoints.up('sm')]: {
			lineHeight: 0,
			whiteSpace: 'nowrap',
			'&:nth-child(1)': {
				marginBottom: '0.5rem',
			}
		},
		[theme.breakpoints.up('md')]: {
			width: '48%',
		},
	},
	paper: {
		backgroundColor: 'white',
		position: 'relative',
		border: 'none',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'center',
		alignItems: 'center',
		width: '94%',
		margin: '0 3rem',
		padding: '46px 4rem 42px',
		maxWidth: '116rem',
		borderRadius: '5px',
		[theme.breakpoints.up('sm')]: {
			margin: '0 auto',
			maxWidth: '126rem',
			padding: '9rem 5rem',
		}
	},
}));

export default function EntryModal({ siteAudience, externalLink }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);

	// const handleOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
		setOpen(false);
		sessionStorage.setItem('showEntryModal', false);


		let body_classname = document.body.className;
		// console.log(body_classname + ": modal close");

		let healthcare_modal_label;
		let healthcare_modal_category;

		switch (body_classname) {
			case 'gateway-home':
				healthcare_modal_label = "https://www.auryxiahcp.com/";
				healthcare_modal_category = "Home page";
				break;
			case 'hpp':
				healthcare_modal_label = "https://www.auryxiahcp.com/hyperphosphatemia-treatment/";
				healthcare_modal_category = "Hyperphosphatemia hcp home page";
				break;
			case 'ida':
				healthcare_modal_label = "https://www.auryxiahcp.com/iron-deficiency-anemia/";
				healthcare_modal_category = "Iron deficiency anemia hcp home page";
				break;
		}

		window.dataLayer.push({
			'event': 'gtm-internal-link',
			'attributes': {
				'eventAction': 'Internal Link - body',
				'eventLabel': healthcare_modal_label,
				'eventCategory': healthcare_modal_category
			}

		});


	};
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={[classes.modal, siteAudience].join(' ')}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			id="entry-modal"
		>
			<Fade timeout={{ enter: 1000, exit: 800, }} in={open}>
				<div className={classes.paper}>
					<IconButton
						color="inherit"
						aria-label="close modal"
						onClick={handleClose}
						id="close-button"
						className={classes.modalCloseBttn}>
						<CloseIcon viewBox="6 6 12 12" className={classes.modalCloseSvg} />
					</IconButton>

					<img src={logo} alt="AURYXIA® (ferric citrate) tablets" className={classes.modalLogo} />
					<p id="spring-modal-title" className={classes.modalText}>The information contained on this site is intended for healthcare professionals in the United&nbsp;States&nbsp;only.</p>
					<div className={classes.bttnWrap}>
						<button onClick={handleClose} className={classes.cta}><span>I AM A US HEALTHCARE PROFESSIONAL</span></button>
						<a href={externalLink} className={classes.cta}><span>I AM NOT A US HEALTHCARE PROFESSIONAL</span></a>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}
