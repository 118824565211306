import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import logo from "../../images/Auryxia-logo.svg";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexFlow: 'row wrap',
        outline: '1px dotted #eee',
    },
    modalCloseBttn: {
        position: 'absolute',
        top: '18px',
        right: '18px',
        width: '20px',
        height: '20px',
    },
    modalCloseSvg: {
        width: '20px',
        height: '20px',
    },
    modalLogo: {
        maxWidth: '180px',
        height: 'auto',
    },
    modalHeader: {
        width: "100%",
        fontSize: "45px",
        lineHeight: "48px",
        fontFamily: "'garage-gothic', 'Arial Narrow', sans-serif",
        margin: '0',
        paddingTop: "2rem",
        [theme.breakpoints.up('sm')]: {
            fontSize: "54px",
            lineHeight: "64px",
            paddingTop: "1rem",
        },
    },
    modalText: {
        width: "100%",
        fontFamily: "'Avenir LT W01_95 Black1475556', sans-serif",
        margin: '0',
        color: '#BE5713',
        fontSize: '21px',
        lineHeight: '24px',
        padding: "2rem 0",
        [theme.breakpoints.up('sm')]: {
            padding: "1rem 0",
            fontSize: '24px',
            lineHeight: '40px',
            //margin: '6rem auto 6.75rem',
            width: '100%'
        },
    },
    bttnWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexFlow: 'row wrap',
        width: '100%',
        paddingBottom: "1rem",
    },
    cta: {
        margin: "1rem",
        whiteSpace: "nowrap",

        width: 'auto',
        minHeight: '45px',
        minWidth: "300px",

        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    paper: {
        backgroundColor: 'white',
        outline: "none",
        backgroundPositionX: "100%",
        backgroundSize: "cover",
        position: 'relative',
        border: 'none',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '94%',
        margin: '0 3rem',
        padding: '46px 4rem 42px',
        maxWidth: '116rem',
        borderRadius: '5px',
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(/no-vacancy-modal/stillShots_06_lean_armsCrossed0.png)`,
            margin: '0 auto',
            maxWidth: '116rem',
            padding: '5rem 28rem 5rem 5rem',
        }
    },
    patientSection: {
        fontFamily: "'Avenir LT W01_85 Heavy1475544', sans-serif",
        fontSize: "15px",
        lineHeight: "27px",
        color: "#404040",
        width: "100%",
        marginTop: "0",
        [theme.breakpoints.up('md')]: {
            paddingBottom: "3rem",
        }
    },
    patientLink: {
        color: "#404040 !important",
        textDecoration: "underline !important",
    },
    patientLinkContainer: {
        [theme.breakpoints.down('xs')]: {
            display: "block",
        },
    },
    footnote: {
        position: "absolute",
        margin: "0",
        bottom: "1rem",
        fontFamily: "'Avenir LT W01_55 Roman1475520', sans-serif",
        fontSize: "12px",
        lineHeight: "27px",
    }
}));

export function getNoVacancyParamValue(location) {
    if (location) {
        const params = new URLSearchParams(location.search);
        return params.get("noVacancy") === "true";
    }
}

export default function NoRepModal({ siteAudience, externalLink }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        // sessionStorage.setItem('showEntryModal', false);
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={[classes.modal, siteAudience].join(' ')}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            id="no-vacancy-modal"
        >
            <Fade timeout={{ enter: 1000, exit: 800, }} in={open}>
                <div id="no-vacancy-modal-container" className={classes.paper}>
                    <IconButton
                        color="inherit"
                        aria-label="close modal"
                        onClick={handleClose}
                        id="close-button"
                        className={classes.modalCloseBttn}>
                        <CloseIcon viewBox="6 6 12 12" className={classes.modalCloseSvg} />
                    </IconButton>

                    <img src={logo} alt="AURYXIA® (ferric citrate) tablets" className={classes.modalLogo} />
                    <h1 className={classes.modalHeader}>No sales representative? No problem.</h1>
                    <p id="spring-modal-title" className={classes.modalText}>You can still get the AURYXIA information you need:</p>
                    <div className={classes.bttnWrap}>
                        <Link to="/hyperphosphatemia/sign-up/?request-rep=true" className={`cta hpp ${classes.cta}`}>Contact a Representative</Link>
                        <Link to="/hyperphosphatemia/ferric-citrate-efficacy/" className={`cta hpp ${classes.cta}`}>Efficacy Results</Link>
                    </div>
                    <p className={classes.patientSection}>Not a healthcare professional? <span className={classes.patientLinkContainer}><a className={classes.patientLink} href={"https://www.auryxia.com/hyperphosphatemia/patients/"}>Go to the patient site.</a></span></p>
                    <p className={classes.footnote}>PP-AUR-US-1511   12/21</p>
                </div>
            </Fade>
        </Modal>
    );
}
